.navbar {
  display: flex; /* Use Flexbox for layout */
  align-items: center; /* Center vertically */
  justify-content: space-between; /* Space items apart */
  padding: 0 10px; /* Add padding to the left and right */
  background-color: #000; /* Example background color */
}

.navbar-logo {
  color: white; 
  font-family: Iceland; 
  text-decoration: none; 
  margin: 0; /* Ensures no additional margin */
  padding-left: 0; /* Ensures no extra padding on the left */
  font-size: 2rem; /* Base size */
  display: flex; /* Ensures correct positioning */
  justify-content: flex-start
}

/* Media query for tablets and larger phones */
@media (max-width: 768px) {
  .navbar-logo {
    margin-top: 5px;
    font-size: 1.5rem; /* Adjusts size for smaller screens */
  }
}

/* Media query for smaller phones */
@media (max-width: 480px) {
  .navbar-logo {
    margin-top: 12px;
    font-size: 1.5rem; /* Keeps size larger for small devices */
  }
}


.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

/* Nav menu for desktop */
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

/* Nav items */
.nav-item {
  display: flex;
  align-items: center;
  height: 160px;
  font-family: 'Iceland';
}

/* Links inside nav */
.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: green;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

/* Mobile-specific styles */
.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* Mobile view adjustments */
@media screen and (max-width: 960px) {

  /* Adjust the main navbar styles for mobile */
  .NavbarItems {
    position: relative;
  }

  /* Nav menu for mobile */
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Full screen height */
    position: absolute;
    top: 60px; /* Reduced top margin */
    left: -100%; /* Initially hidden off-screen */
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: black;
    left: 0;
    top: 80px; /* Adjusted to align with navbar */
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  /* Links styling in mobile */
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: green;
    border-radius: 0;
  }

  /* Logo position for mobile */
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  /* Menu icon positioning */
  .menu-icon {
    display: block;
    position: absolute;
    top: 20px; /* Adjusted top to reduce margin */
    right: 20px; /* Padding from the right */
    transform: none; /* Removed transform */
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  /* Mobile-specific nav links */
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: goldenrod;
    text-decoration: none;
    color: #000;
    font-size: 1.5rem;
    font-family: 'Iceland';
  }

  .nav-links-mobile:hover {
    background: green;
    color: black;
    transition: 250ms;
  }

  /* Hide buttons on mobile */
  button {
    display: none;
  }
}
