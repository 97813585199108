/* Footer Styles */
footer {
    background-color: black;
   
    padding: 60px;
    margin-top: 25px;;
  }
  
  .footer-container {
    justify-content: flex-start; /* Align items to the top */
    align-items:last baseline;
    max-width: 1200px;
    margin: 0 auto;
  }
 
  .columns {
    display: flex;
    justify-content: space-between; 
   
  }
  
  
  
  .boletinera {
    font-size: 2vw;
    color:goldenrod;
    margin-right: 50px; 
    font-family: 'Iceland';
    font-size:1.5rem;
  }
  
 .tele-boletinera {
  font-size: 1.2rem;
  color:goldenrod;
  margin-right: 50px; 
  font-family: 'Iceland';
  
}

  
  
  
 
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    
  
    
  }
  .redes-container {
    text-align: center;
  }
  
  .redes-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .redes-list li {
    margin-bottom: 1rem;
  }
  
 
  
  .third-party-icons {
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    gap: 5px;
    margin-left:10px;
    margin-top:40px;
  }
  .third-party-icons>* {
    flex: 10 1 20%;
  }
  
  
  .third-party-grid {
    font-size: 2.5vw;
    color:goldenrod;
    text-decoration: none;
  }
  
  .third-party-grid:hover {
    color: red;
  }
  .footer-banner {
    width: 30vw;            /* Makes the image width responsive */
    height: auto;           /* Maintains the aspect ratio of the image */
    border-width: 2px;      /* Sets the border width to 5 pixels */
    border-color: black;    /* Sets the border color to black */
    border-style: solid;    /* Sets the border style to solid */
}
 

  .input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Adjust spacing between email input and columns */
  }
  
  .input-wrapper {
    position: relative;
    width: 300px;
    display: flex;
    align-items: center;
  }
  
  
  .searchbar {
    background-color: black;
    width: 70%;
    height: 40px;
    color: goldenrod;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: orange;
    border-width: 3px;
    border-radius: 10px;
    ;
  }
 
  .search-bar {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
  }
 
  .searchbar::placeholder {
    color: orange;
    font-size: 1.5vw;
  }
  .organized-list{
    text-decoration: none;
    list-style-type: none;
  }
 
  
  
  