 * {
   box-sizing: border-box;
 }


 @import url('https://fonts.googleapis.com/css2?family=Henny+Penny&family=Iceland&display=swap');

 body {
  overscroll-behavior: none; 
   margin: 0;
   max-width: fit-content;
   background-size: 100%;

 }

 #particles canvas{
  z-index: -1;
  height: 100%;
  width:100%;
  position: absolute;

 }

 



 section {
   padding: 20px;
 }

 .cards-list {
   display: flex;
   flex-wrap: nowrap;
   gap: 20px;
   overflow-x: auto;
   
 }
 /* Video Container */
 




 .card {
   width: 200px;
   font-size: 12px;
   flex: 0 0 auto;
   /* ADD */
   display: flex;
   /* ADD */
   flex-direction: column;
   margin-top: 5px;
   /* ADD */
   background-color: black;
   border-radius: 10px;
   padding: 10px;
   position: relative;
   color: #f4c44b;
 }

 .sharebutton {
  display:flex;
  flex-direction: row;
   
   }
.sharebutton2 {
  display: flex;
  flex-direction: row;
  font-size: 25px;
  color: limegreen;
  margin-right: 75px;
}
.sharebutton1 {
  display: flex;
  flex-direction: row;
  font-size: 25px;
  color: #dc9e02;
  margin-right: 5px;
}
 .headertext {
   color: #dc9e02;;
  font-style: bold;
   font-family: 'Henny Penny';
   font-size: 1.5rem;
   margin-top: 10px;
   text-align: center;
   
   padding: 10px 20px;
   border-radius: 20px;
   
 }


 .card--image {
   width: 100%;
   border-radius: 9px;
   margin-bottom: 9px;
 }

 /* ADD */
 .card--title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2.0rem;
  font-family: 'Iceland';
  color: #f4c44b;
  text-decoration: none; /* Ensures no underline is applied */
}
.card--link {
  text-align: center;
  text-decoration: none; /* Removes underline from links */
}
.play-button{
  font-size: 65px;
  color:goldenrod;
  margin-top: 50px;
}

 .card--stats {
   display: flex;
   align-items: center;
   color: #f4c44b;
   font-weight: bold;
   font-family: 'Iceland';
   font-size: 1rem;
 }

 .title2 {
   text-align: center;
   font-family: 'Notable';
   font-size: 1.5rem;
   color:white;

 }

 .headerlogo {

   margin: 25px;
 }

.stars{
  margin-top:-40px;
}


 .flag-logo2 {
   width: 70px;
   height: 70px;
   text-align: center;


 }

 





 .category {
   display: flex;
   align-items: center;
   color: #f4c44b;
   font-size: 1.2rem;
   margin-top: 10px;
   font-weight: bold;
   font-family: 'Iceland';

 }

 /* ADD */
 .card--price {
   margin-bottom: auto;
 }



 .intro {
   background-color: black;
   text-align: center;
   border-radius: 20px;
   margin-top: 10px;
   margin-left: 45px;
   margin-right: 45px;
   font-size: 1rem;
   font-family: 'Iceland';
   color: #f4c44b;
 }


 .slidertextdiv {

   text-align: center;
   border-radius: 20px;
   margin-top: 10px;
   margin-left: 45px;
   margin-right: 45px;
   font-size: 1rem;
   font-family: 'Iceland';
   color: #f4c44b;
 }

 .slidertext {

   font-size: 1rem;
   font-family: 'Iceland';
   text-align: center;
   border-radius: 10px;
   margin-left: 0 auto;
   margin-right: 0 auto;
   margin-top: 25px;
   color: #f4c44b;
 }


 .herotext {
   font-size: 2rem;
   font-family: 'Iceland';
   text-align: center;
   background-color: black;
   border-radius: 15px;
   color: #f4c44b;
 }



 .eventos {
   background-color: black;
   font-size: 3rem;
   font-family: 'Iceland';
   text-align: center;
   border-radius: 10px;
   margin-left: 0 auto;
   margin-right: 0 auto;
   color: #f4c44b;
 }

 .description {
   color: #f4c44b;
   font-family: 'Asap Condensed';
 }

 .socialmedia {
   background-color: black;
   font-size: 2rem;
   font-family: 'Iceland';
   text-align: center;
   border-radius: 10px;
   margin-left: 0 auto;
   margin-right: 0 auto;
   margin-top: 25px;
   color: #f4c44b;
 }

 .row {
   display: flex;
   padding: 5px;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: space-around;
   margin-left: 10px;
   margin-top: 10px;
 }

 .card--badge {
   position: absolute;
   top: 6px;
   left: 6px;
   background-color: #dc9e02;
   color: black;
   padding: 5px 7px;
   border-radius: 2px;
   font-weight: bold;
   font-family:'Iceland'
 }

 .video {
   color: goldenrod;
   font-size: 3rem;
 }




 .player-wrapper {
   position: relative;
   bottom: 800px;
   padding-top: 56.25%;
   width: 95%;
   display: block;
   margin: auto;



   /* Player ratio: 100 / (1280 / 720) */
 }

 .react-player {
   position: absolute;
   top: 0;
   left: 0;
   font-size: 4rem;
   color: black;

 }

 .react-player2 {
   position: absolute;
   top: 0;
   left: 0;
   font-size: 4rem;
   color: goldenrod;
 }



 .like-icons {
   display: flex;
   flex-direction: row;
   margin-bottom: 5px;
   font-family: 'Iceland'
 }

 .icons-like {
   font-size: 25px;
   color: #dc9e02;
   margin-left: 5px;
   font-family: 'Iceland'
 }

 .num-display {
   position: absolute;
   left: 50px;
   font-family: 'Iceland'
 }

 .locationImg {
   width: 30px;
   position: relative;
   left: 48px;
   bottom:45px;

 }

 .icons-bandera {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: red;
   border-radius: 10%;
   text-decoration: none;

 }
.navbar-logo
 .icons-bandera :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-fb {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: blue;
   border-radius: 10%;
   text-decoration: none;

 }

 .icons-fb :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-YT {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: red;
   background-color: none;
   border-radius: 10%;
 }

 .icons-YT :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-twitter {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   padding: 1px;
   font-size: 45px;
   color: white;
   background-color: none;
   border-radius: 10%;
 }

 .icons-twitter :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-instagram {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: rgb(243, 9, 138);
   border-radius: 10%;
 }

 .icons-instagram :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-spotify {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: rgb(9, 243, 9);
   background-color: none;
   border-radius: 10%;
 }

 .icons-spotify :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 
 .icons-ticketmaster {
  color:hotpink;
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 20px;
   font-size: 45px;
   color: none;
   background-color: none;
   border-radius: 10%;
 }

 .icons-ticketmaster :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-itunes {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: hotpink;
   background-color: none;
   border-radius: 10%;
 }

 .icons-itunes :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }



 .icons-tiktok {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: white;
   background-color: none;
   border-radius: 10%;
 }

 .icons-tiktok :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-wiki {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: grey;
   background-color: none;
   border-radius: 10%;
 }

 .icons-wiki :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-tidal {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: white;
   background-color: none;
   border-radius: 10%;
 }

 .icons-tidal :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-soundcloud {
   margin-top: 4px;
   margin-bottom: 2px;
   margin-right: 10px;
   font-size: 45px;
   color: orange;
   background-color: none;
   border-radius: 10%;
 }

 .icons-soundcloud :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .icons-napster {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: red;
  background-color: black;
  border-radius: 10%;
}

 .icons-napster :hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }




 .third-party-icons {
   display: flex;
   flex-wrap: wrap;
 }

 .third-party-icons>* {
   flex: 0 1 20%;
 }

 #third-party-grid {
   width: 55px;
   height: 55px;
   border-radius: 20%;
   margin: 2px;
 }



 .sponsorcontainer {
   display: flex;
   flex-wrap: wrap;
   text-align: center;
   margin-left:25px;
 }

 .sponsorcontainer>* {
   flex: 0 1 20%;
 }

 .sponsor {
   width: 200px;
   height: 200px;
   border-radius: 20%;
   margin: 9px;
   margin-top: 20px;
 }
 .sponsor {
  transition: background-color 0.3s ease, transform 0.3s ease, padding 0.3s ease; /* Smooth transition */
}

.sponsor:hover {
   background-image: linear-gradient(to right, #dc9e02, black, #dc9e02); /* Gradient effect */
   width: 200px; /* Increase width on hover */
   height: 200px; /* Increase height on hover */
   padding: 20px; /* Add padding for more space */
   border-radius: 50%; /* Round shape */
   cursor: pointer; /* Pointer cursor on hover */
   border: 5px solid black; /* Border styling */
   transform: scale(1.1); /* Slightly increase size for hover effect */
}


/* Add this in your CSS file (e.g., styles.css) */
.btn button {
  font-family: 'Iceland', sans-serif;
  font-size: 1rem;
  color: black;
  background-image: linear-gradient(to right, #dc9e02, white, #dc9e02);
  padding: 20px 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height:100%;
  max-width: 600px;
  box-sizing: border-box;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Hover effect */
.btn button:hover {
  background-image: linear-gradient(to right, green, red, green);
  color: white; /* Change text color to white */
  transform: scale(1.05); /* Slightly enlarge the button */
}

/* Responsive styles */
@media (max-width: 768px) {
  .btn button {
    max-width: 100%;
    
  }
}

@media (max-width: 480px) {
  .btn button {
    font-size: 1.2rem; /* Adjust font size for very small screens */
    padding: 15px 30px; /* Adjust padding for very small screens */
  }
}


 .sponsorcontainer1 {
   display: flex;
   flex-wrap: wrap;
   margin: 20px;
 }

 .sponsorcontainer1>* {
   flex: 5 1 5%;
 }

 .sponsor1 {
   width: 55px;
   height: 55px;
   border-radius: 20%;
   margin: 0px;
 }



 .sponsor1:hover {
   background-image: linear-gradient(to right, #dc9e02,
       black,
       #dc9e02);
   max-width: 50px;
   max-height: 50px;
   padding: 1px;
   border-radius: 10%;
   cursor: pointer;
 }






 .tm-icon {
   width: 50px;
   height: 50px;
   border-radius: 20%;
   margin: 5px;
   margin-top: 25px;
 }

 .tm-icon:hover {
   background-image: linear-gradient(to right, #dc9e02, black, #dc9e02);
   max-width: 95px;
   max-height: 95px;
   padding: 5px;
   border-radius: 10%;
   cursor: pointer;
 }

 .navbar {
   background: black;
   height: 80px;

 }

 .navbar-logo {
   position: absolute;
   top: 5px;
   left: 80px;
   transform: translate(5%, 50%);


 }


 .flag-logo {
   position: relative;
   left: 230px;
   width: 80px;
   height: 60px;
 }


 ::-webkit-scrollbar {
   width: 1rem;
 }

 ::-webkit-scrollbar-track {
   width: 2rem;
   background-color: black;
 }

 ;

 ::-webkit-scrollbar-thumb {
   background-color: #dc9e02;
   border-radius: 4px;

 }

 ;

 .comments {
   position: absolute;
   bottom: -80px;
   left: 0px;
   margin: 10px;
 }

 .comment-form-title {
   font-size: 20px;
   background-color: black;
   font-family: 'Asap Condensed';
   margin-bottom: -5px;
   margin-top: -5px;
   color: #f4c44b;
 }

 .comment-form-textarea {
   width: 100%;
   height: 40px;
   margin-bottom: 8px;
   margin-top: 8px;
   border: 1px solid #f4c44b;
   font-family: 'Asap Condensed';
   font-size: 20px;
   background-color: black;
   color: #f4c44b;
   border-radius: 10px;
 }

 .comment-form-button {
   font-size: 14px;
   padding: 6px 12px;
   background-color: #dc9e02;
   border-radius: 8px;
   color: black;
   font-family: 'Asap Condensed';
   position: relative;
   top: 0px;
   right: 40px;
   margin-left: 50px;


 }


 .comment-form-button:hover:enabled {
   cursor: pointer;
   background: black;
 }

 .comment-form-button:disabled {

   cursor: default;
 }

 .comment-form-cancel-button {
   margin-left: 10px;
 }

 .comment {
   display: flex;
   margin-bottom: 0px;
   font-family: 'Asap Condensed';
   color: #f4c44b;
 }

 .comment-image-container {
   margin-right: 12px;
 }

 .comment-image-container img {
   border-radius: 50px;
 }

 .comment-right-part {
   width: 100%;
 }

 .comment-content {
   display: flex;
 }

 .comment-author {
   margin-right: 8px;
   font-size: 20px;
   color: #f4c44b;
   font-family: 'Asap Condensed';
 }

 .comment-text {
   font-size: 15px;
   font-family: 'Asap Condensed';
   color: #f4c44b;
 }

 .comment-actions {
   display: flex;
   font-size: 15px;
   color: silver;
   cursor: pointer;
   margin-top: 0px;
   margin-bottom: 0px;
 }

 .comment-action {
   margin-right: 8px;
 }

 .comment-action:hover {
   text-decoration: underline;
 }

 .replies {
   margin-top: 10px;
   position: relative;
   left: -40px
 }

 .comments-container {
   position: relative;
   bottom: 5px;
   height: 200px;
   width: 100%;
   padding: 30px;
   border-radius: 10px;
   background-color: black;
   margin-bottom: 10px;
   margin-top: 40px;
   overflow: hidden;
   overflow-y: scroll;
   border-width: 5px;
   border-color: #f4c44b;
   border: 1px solid #f4c44b;


 }

 ::-webkit-scrollbar {
   width: .8rem;
 }

 ::-webkit-scrollbar-track {
   width: 1rem;
   background: black;
   border-radius: 5px;
 }

 ::-webkit-scrollbar-thumb {
   background-color: #dc9e02;
   border-radius: 5px;
 }

 .sponsorcontainer1
 

 .btn {
   margin: 3px;
 }

 .btn2 {
   position: relative;
   left: 110px;
   bottom: -10px;
   width: 50px;
   height: 70px;
   text-align: center;
   font-size: 45px;
   background-image: linear-gradient(to right, red, white, green);
   color: black;
   border-radius: 10px;
 }

 .btn2:hover {
   background-image: linear-gradient(to right, green, white, red);
   max-width: 95px;
   max-height: 95px;
   border-radius: 10%;
   cursor: pointer;

 }

 .app-download-banner {
   background-color: #f0f0f0;
   padding: 10px;
   text-align: center;
 }

 .app-download-banner p {
   margin: 0;
   font-size: 14px;
 }

 .app-download-banner a {
   display: block;
   margin-top: 5px;
 }

 .app-download-banner img {
   height: 40px;
 }

 .searchbar {
   background-color: black;
   width: 350px;
   height: 60px;
   font-family: "Iceland";
   color: goldenrod;
   justify-content: center;
   align-items: center;
   text-align: center;
   border-color: goldenrod;
   border-width: 3px;
   border-radius: 10px;
   ;
 }

 .search-bar {
   display: flex;
   justify-content: center;
   margin-top: 20px;
   /* Adjust the margin as needed */
 }

 .searchbar::placeholder {
   font-family: 'Iceland', sans-serif;
   color: hotpink;
   font-size: 1rem;
   ;
   /* Replace 'YourFontName' with the desired font name */
   /* Add any other desired styles for the placeholder text */
 }
 
.arrow {
  position:fixed;
    top: 50%;
    transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: hotpink;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}
.scroll-container {
  position: relative;
  overflow-x: scroll;
  white-space: nowrap;
  /* Ensures the cards are displayed in a single line */
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
.sharebutton1{
  background-color: black;
  border:10px;
}





