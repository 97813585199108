/* General Styles */
 * {
   box-sizing: border-box;
 }




 body {
   margin: 0;
   width: 100%;
   overflow-x: hidden;
   background-image: url("/public/images/SPACIALVID.gif")
}

  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Video Container */
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    background: #000;
    margin-bottom: 20px;
  }
  
  .video-container .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Band Card */
  .band-card {
   
   background-size: 100%;

    padding: 20px;
    border-radius: 10px;
   font-family: 'Notable';
    margin-bottom: 20px;
    text-align: center;
  }
  
  .band-card h2 {
    font-size: 2rem;
    font-family: "Notable";
    margin: 1px 0;
    color: goldenrod ;
  }
  
  .band-card p {
    font-size: 12px;
    color: goldenrod ;

  }
  
  .band-card img.coverImg {
    width: 100%;
    max-width: 300px;
    height: auto;
    max-height: 400px;
    border-radius: 10px;
    margin: 10px;
}

.band-card img.locationImg {
    width: 100%;
    max-width: 150px;
    height: auto;
    border-radius: 10px;
    margin-left: 200px;
    position: relative;
    bottom: 300px;
}

.sharebutton {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust as needed */
  margin-top: 65px;
  
}

.sharebutton-button,
.sharebutton-link {
  background-color: black;
  color: goldenrod;
  border-radius: 10px;
  border: none;
  font-size: 40px;
  cursor: pointer; /* Indicate interactive elements */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}
.sharebutton-button-2 {
  background-color: black;
  color: green;
  border: none;
  font-size: 40px;
  cursor: pointer; /* Indicate interactive elements */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.sharebutton-button:hover,
.sharebutton-link:hover {
  color: white; /* Optional: Change color on hover for better UX */
}

  
  /* Third-Party Icons */
  .third-party-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;
  }
  
  .third-party-icons a,
  .third-party-icons img {
    font-size: 45px;
  
    transition: transform 0.3s;
  }
  
  .third-party-icons a:hover,
  .third-party-icons img:hover {
    transform: scale(1.1);
  }
  
  .third-party-icons img.sponsor {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  /* Comments Section */
  .comments-section {
    margin-top: 20px;
  }
  
  /* Slider Text */
  .slidertextdiv {
    text-align: center;
    margin: 20px 0;
  }
  
  .slidertext {
    font-size: 22px;
    font-weight: bold;
  }
  
  /* Star Rating */
  .star-rating {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  
  
  .origin-flag {
    width: 55px;
    height: 35px;
    border-radius: 10px;; /* Adjust this to the desired height */
}

.icons-bandera {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: red;
  border-radius: 10%;
  text-decoration: none;

}
.navbar-logo
.icons-bandera :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-fb {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: blue;
  border-radius: 10%;
  text-decoration: none;

}

.icons-fb :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-YT {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: red;
  background-color: none;
  border-radius: 10%;
}

.icons-YT :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-twitter {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  padding: 1px;
  font-size: 45px;
  color: white;
  background-color: none;
  border-radius: 10%;
}

.icons-twitter :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-instagram {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: rgb(243, 9, 138);
  border-radius: 10%;
}

.icons-instagram :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-spotify {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: rgb(9, 243, 9);
  background-color: none;
  border-radius: 10%;
}

.icons-spotify :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-ticketmaster {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 20px;
  font-size: 45px;
  color: none;
  background-color: none;
  border-radius: 10%;
}

.icons-ticketmaster :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-itunes {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: hotpink;
  background-color: none;
  border-radius: 10%;
}

.icons-itunes :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}



.icons-tiktok {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: white;
  background-color: none;
  border-radius: 10%;
}

.icons-tiktok :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-wiki {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: grey;
  background-color: none;
  border-radius: 10%;
}

.icons-wiki :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-tidal {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: white;
  background-color: none;
  border-radius: 10%;
}

.icons-tidal :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-soundcloud {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: orange;
  background-color: none;
  border-radius: 10%;
}

.icons-soundcloud :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}

.icons-napster {
  margin-top: 4px;
  margin-bottom: 2px;
  margin-right: 10px;
  font-size: 45px;
  color: red;
  background-color: none;
  border-radius: 10%;
}

.icons-napster :hover {
  background-image: linear-gradient(to right, #dc9e02,
      black,
      #dc9e02);
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
}




.third-party-icons {
  display: flex;
  flex-wrap: wrap;
}

.third-party-icons>* {
  flex: 0 1 20%;
}

#third-party-grid {
  width: 55px;
  height: 55px;
  border-radius: 20%;
  margin: 2px;
}

