.bibliography-container {
    text-align: center;
    font-family:'Iceland';
    width:100%;
    margin:20px;
    color: #f4f4f4; /* Light background color for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .bibliography-title {
    font-size: 24px; /* Larger font for the title */
    margin-bottom: 15px; /* Space below the title */
    color: white; /* Dark text color */
  }
  .bibliography-props {
    font-size: 24px; /* Larger font for the title */
    margin-bottom: 15px; /* Space below the title */
    color: white; /* Dark text color */
    margin-left: 25px;
    margin-right:25px;
  }
  
  .bibliography-list {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
  }
  
  .bibliography-item {
    margin: 10px 0; /* Space between list items */
    font-size: 2rem; /* Regular font size */
    color: white; /* Medium text color */
  }
  
  .bibliography-link {
    color: #007bff; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .bibliography-link:hover {
    text-decoration: underline; /* Underline on hover for interaction */
    color: #0056b3; /* Darker shade on hover */
  }
  .logo{
    text-align:center;
  }
  