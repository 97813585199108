.servicios-container {
    padding: 20px;
    /* Fondo claro */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  }
  
  .servicios-title {
    font-size: 3rem; /* Tamaño de fuente para el título */
    margin-bottom: 15px; /* Espacio debajo del título */
    color: white;
    font-family: "Iceland" /* Color oscuro */
  }
  
  .servicios-list {
    list-style-type: none; /* Sin viñetas */
    padding: 0; /* Sin padding por defecto */
  }
  
  .servicios-item {
    margin: 30px 0; /* Espacio entre elementos de la lista */
    font-size: 16px; /* Tamaño de fuente regular */
    color: white;
    font-family: "Iceland";
    font-size:2rem /* Color de texto medio */
  }
  