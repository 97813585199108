.marquee-container { 
  width: 90%; /* Ensure container takes full width */
  max-width: 100vw; /* Prevent overflow beyond the viewport width */
  overflow: hidden; /* Hide overflow */
  background-color: transparent;
  padding: 10px 0;
  box-sizing: border-box; /* Add this line */
}

.marquee-content {
  display: flex;
  animation: marquee 35s linear infinite;
  will-change: transform;
  flex-wrap: nowrap;  /* Prevent items from wrapping */
}

.marquee-item {
  flex: 0 0 auto;
  margin: 0 20px; /* Adjusted margin for better spacing */
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
}

.marquee-image {
  width: 65px;
  height: 65px;
  border-radius: 10px;
}

/* Keyframe animation for continuous horizontal scrolling */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .marquee-container {
    padding: 5px 0; /* Adjust padding for mobile */
  }

  .marquee-content {
    animation: marquee 25s linear infinite; /* Adjust speed for mobile */
  }

  .marquee-item {
    font-size: 18px; /* Adjust font size for smaller screens */
    margin: 0 10px; /* Reduce margin on mobile */
  }

  .marquee-image {
    width: 50px; /* Reduce image size on mobile */
    height: 50px;
  }
}
