.react-player3 {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 4rem;
  color: blue;

}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 95%;
  display: block;
  margin: 45px;

  /* Player ratio: 100 / (1280 / 720) */
}

.third-party-icons2 {
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;

}

.third-party-icons>* {
  flex: 0 1 20%;
}

#third-party-grid {
  width: 55px;
  height: 55px;
  border-radius: 20%;
  margin: 6px;
}

.sharebutton {
  width: 50px;
  font-size: 25px;
  color: #dc9e02;
  background-color: black;
  position: relative;
  bottom: 45px;
  left: 150px;
}

.bandera-icons {
  display:flex;
  flex-direction: row;
  font-size: 20px;
  margin-top: 25px;
}

.bandera {
  display: flex;
  flex-direction: row;
  color: #dc9e02;
  margin: 5px;
  font-size:35px;
}
.contact{
  font-size: 1rem;
}